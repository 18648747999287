@font-face {
font-family: 'PresicavHv';
src: url(/_next/static/media/439cde634d8c5a54-s.p.woff2) format('woff2');
font-display: swap;
}

@font-face {
font-family: 'PresicavHv';
src: url(/_next/static/media/513ba667c139f061-s.p.woff) format('woff');
font-display: swap;
}

@font-face {
font-family: 'PresicavHv';
src: url(/_next/static/media/2ddaaa045197427a-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: 'PresicavHv Fallback';src: local("Arial");ascent-override: 61.71%;descent-override: 16.27%;line-gap-override: 1.76%;size-adjust: 153.63%
}.__className_2d63ef {font-family: 'PresicavHv', 'PresicavHv Fallback'
}

